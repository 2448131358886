<template>

<app-content-box title="Account" icon="account" :freeze="status === $constants.status.loading">

	<app-input-text label="First name" v-model="model.firstname" placeholder="Enter your first name" maxlength="32" />
	<app-input-text label="Last name" v-model="model.lastname" placeholder="Enter your last name" maxlength="32" />
	<app-input-account label="E-mail" :original="$store.getters['session/email']" endpoint="session/exists" :reverse="true" type="profile" :text="emailMessages" v-model="model.email" :validation="$v.model.email" v-on:exists="onExistsChange" />
	<app-input-phone label="Mobile number" v-model="model.phone" placeholder="Enter your phone number" maxlength="32" notes="Optional: Some features let you opt-in to SMS notifications such as library waiting lists." />
	<app-button text="Save" :disabled="!isValid" v-on:click="onSubmitClick" :status="status" />

</app-content-box>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, email } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			standalone: true,
			status: false,
			model: {
				firstname: '',
				lastname: '',
				email: '',
				phone: ''
			},
			emailMessages: {
				info: 'Please note if you wish to change e-mail address it cannot already be associated with any other user profile.',
				exists: 'This e-mail address is already associated with another profile.',
				available: 'This e-mail is available to use.'
			},
			exists: false
		}

	},

	validations: {
		model: {
			firstname: {
				required
			},
			lastname: {
				required
			},
			email: {
				required,
				email,
				notExist: function() {

					return !this.exists

				}
			}
		}
	},
	
	created: function() {

		this.model.firstname = this.$store.getters['session/firstname']
		this.model.lastname = this.$store.getters['session/lastname']
		this.model.email = this.$store.getters['session/email']
		this.model.phone = this.$store.getters['session/phone']

		this.$nextTick(function() {
			
			this.$v.$reset()

		}.bind(this))

	},

	methods: {

		onExistsChange: function(e) {

			this.exists = e.exists

		},

		onSubmitClick: function() {

			this.status = this.$constants.status.loading

			this.$api.put('session/account', this.model).then(function() {

				this.status = this.$constants.status.success

			}.bind(this), function() {

				this.status = this.$constants.status.failed

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>
